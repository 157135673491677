/* eslint-disable standard/no-callback-literal */
const testFile = function (rule, value, cb) {
  if (value) {
    if (value.length <= 0) {
      cb(false)
    } else {
      cb()
    }
  } else {
    cb(false)
  }
}
const emailValidator = {
  required: true, type: 'email', message: 'Please input correct email', trigger: 'blur'
}
const fileValidator = { validator: testFile, required: true, message: 'pleas upload correct file', trigger: 'change' }
const baseRequirement = { required: true, message: 'Please fill in the correct value', trigger: 'blur' }

// const testSign = function (rule, value, cb) {
//   console.log(cb)
//   if (value) {
//     cb(true)
//   } else {
//     cb(false)
//   }
// }
export default {
  has_authorize_other: [
    { required: true, message: 'Please Select', trigger: 'change' }
  ],
  authorize1_signature_key: [
    baseRequirement
  ],
  authorize1_dial_code: [
    baseRequirement
  ],
  authorize1_prove_channel: [
    baseRequirement
  ],
  authorize1_suburb: [
    baseRequirement

  ],
  authorize1_id_num: [
    baseRequirement
  ],
  authorize1_expiry_date: [
    baseRequirement
  ],
  authorize1_issue_date: [
    baseRequirement
  ],
  authorize1_issue_country: [
    baseRequirement
  ],
  authorize1_address_prove_file_list: [
    baseRequirement
  ],
  authorize1_title: [
    baseRequirement
  ],
  authorize1_first_name: [
    baseRequirement
  ],
  authorize1_last_name: [
    baseRequirement
  ],
  authorize1_gender: [
    baseRequirement
  ],
  authorize1_identification_type: [
    baseRequirement
  ],
  authorize1_date_of_birth: [
    baseRequirement
  ],
  authorize1_place_of_birth: [
    baseRequirement
  ],
  authorize1_nationality: [
    baseRequirement
  ],
  authorize1_tax_country: [
    baseRequirement
  ],
  authorize1_relation: [
    baseRequirement
  ],
  authorize1_residential_address: [
    baseRequirement
  ],
  authorize1_city: [
    baseRequirement
  ],
  authorize1_country: [
    baseRequirement
  ],
  authorize1_postcode: [
    baseRequirement
  ],
  authorize1_home_telephone: [
    baseRequirement
  ],
  authorize1_mobile_phone: [
    baseRequirement
  ],
  authorize1_email_address: [
    emailValidator
  ],
  authorize11_address_prove_file_list: [
    { ...fileValidator }
  ],
  authorize1_nz_au_passport_num: [
    baseRequirement
  ],
  authorize1_nz_au_passport_country: [
    baseRequirement
  ],
  authorize1_nz_au_passport_file_list: [
    baseRequirement
  ],
  authorize1_nz_au_driver_num: [
    baseRequirement
  ],
  authorize1_nz_au_driver_country: [
    baseRequirement
  ],
  authorize1_nz_au_driver_front_file_list: [
    baseRequirement
  ],
  authorize1_nz_au_driver_back_file_list: [
    baseRequirement
  ],
  authorize1_cn_passport_num: [
    baseRequirement
  ],
  authorize1_cn_passport_country: [
    baseRequirement
  ],
  authorize1_cn_passport_file_list: [
    baseRequirement
  ],
  authorize1_cn_passport_visa_num: [
    baseRequirement
  ],
  authorize1_cn_passport_visa_country: [
    baseRequirement
  ],
  authorize1_cn_passport_visa_file_list: [
    baseRequirement
  ],
  authorize1_cn_identify_front_num: [
    baseRequirement
  ],
  authorize1_cn_identify_front_country: [
    baseRequirement
  ],
  authorize1_cn_identify_front_file_list: [
    baseRequirement
  ],
  authorize1_cn_identify_back_file_list: [
    baseRequirement
  ],
  authorize1_cn_bank_front_num: [
    baseRequirement
  ],
  authorize1_cn_bank_front_country: [
    baseRequirement
  ],
  authorize1_cn_bank_front_file_list: [
    baseRequirement
  ],
  authorize1_cn_bank_back_file_list: [
    baseRequirement
  ],
  authorize1_cn_passport_not_required_num: [
    baseRequirement
  ],
  authorize1_cn_passport_not_required_country: [
    baseRequirement
  ],
  authorize1_cn_passport_not_required_file_list: [
    baseRequirement
  ],
  authorize1_cn_passport_not_required_visa_num: [
    baseRequirement
  ],
  authorize1_cn_passport_not_required_visa_country: [
    baseRequirement
  ],
  authorize1_cn_passport_not_required_visa_file_list: [
    baseRequirement
  ],
  authorize1_other_identify_num: [
    baseRequirement
  ],
  authorize1_other_identify_country: [
    baseRequirement
  ],
  authorize1_other_identify_file_list: [
    baseRequirement
  ],
  authorize1_other_passport_visa_num: [
    baseRequirement
  ],
  authorize1_other_passport_visa_country: [
    baseRequirement
  ],
  authorize1_other_passport_visa_file_list: [
    baseRequirement
  ],
  // /   分割
  authorize2_signature_key: [
    baseRequirement
  ],
  authorize2_dial_code: [
    baseRequirement
  ],
  authorize2_prove_channel: [
    baseRequirement
  ],
  authorize2_suburb: [
    baseRequirement

  ],
  authorize2_id_num: [
    baseRequirement
  ],
  authorize2_expiry_date: [
    baseRequirement
  ],
  authorize2_issue_date: [
    baseRequirement
  ],
  authorize2_issue_country: [
    baseRequirement
  ],
  authorize2_address_prove_file_list: [
    baseRequirement
  ],
  authorize2_title: [
    baseRequirement
  ],
  authorize2_first_name: [
    baseRequirement
  ],
  authorize2_last_name: [
    baseRequirement
  ],
  authorize2_gender: [
    baseRequirement
  ],
  authorize2_identification_type: [
    baseRequirement
  ],
  authorize2_date_of_birth: [
    baseRequirement
  ],
  authorize2_place_of_birth: [
    baseRequirement
  ],
  authorize2_nationality: [
    baseRequirement
  ],
  authorize2_tax_country: [
    baseRequirement
  ],
  authorize2_relation: [
    baseRequirement
  ],
  authorize2_residential_address: [
    baseRequirement
  ],
  authorize2_city: [
    baseRequirement
  ],
  authorize2_country: [
    baseRequirement
  ],
  authorize2_postcode: [
    baseRequirement
  ],
  authorize2_home_telephone: [
    baseRequirement
  ],
  authorize2_mobile_phone: [
    baseRequirement
  ],
  authorize2_email_address: [
    emailValidator
  ],
  authorize21_address_prove_file_list: [
    { ...fileValidator }
  ],
  authorize2_nz_au_passport_num: [
    baseRequirement
  ],
  authorize2_nz_au_passport_country: [
    baseRequirement
  ],
  authorize2_nz_au_passport_file_list: [
    baseRequirement
  ],
  authorize2_nz_au_driver_num: [
    baseRequirement
  ],
  authorize2_nz_au_driver_country: [
    baseRequirement
  ],
  authorize2_nz_au_driver_front_file_list: [
    baseRequirement
  ],
  authorize2_nz_au_driver_back_file_list: [
    baseRequirement
  ],
  authorize2_cn_passport_num: [
    baseRequirement
  ],
  authorize2_cn_passport_country: [
    baseRequirement
  ],
  authorize2_cn_passport_file_list: [
    baseRequirement
  ],
  authorize2_cn_passport_visa_num: [
    baseRequirement
  ],
  authorize2_cn_passport_visa_country: [
    baseRequirement
  ],
  authorize2_cn_passport_visa_file_list: [
    baseRequirement
  ],
  authorize2_cn_identify_front_num: [
    baseRequirement
  ],
  authorize2_cn_identify_front_country: [
    baseRequirement
  ],
  authorize2_cn_identify_front_file_list: [
    baseRequirement
  ],
  authorize2_cn_identify_back_file_list: [
    baseRequirement
  ],
  authorize2_cn_bank_front_num: [
    baseRequirement
  ],
  authorize2_cn_bank_front_country: [
    baseRequirement
  ],
  authorize2_cn_bank_front_file_list: [
    baseRequirement
  ],
  authorize2_cn_bank_back_file_list: [
    baseRequirement
  ],
  authorize2_cn_passport_not_required_num: [
    baseRequirement
  ],
  authorize2_cn_passport_not_required_country: [
    baseRequirement
  ],
  authorize2_cn_passport_not_required_file_list: [
    baseRequirement
  ],
  authorize2_cn_passport_not_required_visa_num: [
    baseRequirement
  ],
  authorize2_cn_passport_not_required_visa_country: [
    baseRequirement
  ],
  authorize2_cn_passport_not_required_visa_file_list: [
    baseRequirement
  ],
  authorize2_other_identify_num: [
    baseRequirement
  ],
  authorize2_other_identify_country: [
    baseRequirement
  ],
  authorize2_other_identify_file_list: [
    baseRequirement
  ],
  authorize2_other_passport_visa_num: [
    baseRequirement
  ],
  authorize2_other_passport_visa_country: [
    baseRequirement
  ],
  authorize2_other_passport_visa_file_list: [
    baseRequirement
  ]
}
